import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PopupGraph = ({ nodes, links }) => {
    const ref = useRef();

    useEffect(() => {
        const svg = d3.select(ref.current);
        const width = 300; // Set the size of the SVG element
        const height = 300;

        svg.selectAll('*').remove(); // Clear previous SVG contents

        const simulation = d3.forceSimulation(nodes)
            .force('link', d3.forceLink(links).id(d => d.id).distance(50))
            .force('charge', d3.forceManyBody().strength(-200))
            .force('center', d3.forceCenter(width / 2, height / 2));

        const link = svg.append('g')
            .selectAll('line')
            .data(links)
            .enter().append('line')
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.6)
            .attr('stroke-width', d => Math.sqrt(d.value));

        const label = svg.append('g')
            .selectAll('text')
            .data(nodes)
            .enter().append('text')
            .attr('class', 'label')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .text(d => d.id)
            .style('font-size', '10px')
            .style('pointer-events', 'none'); // Prevent text from interfering with mouse events

        simulation.on('tick', () => {
            link.attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            label.attr('x', d => d.x)
                 .attr('y', d => d.y);
        });

        return () => simulation.stop();
    }, [nodes, links]);

    return <svg ref={ref} width={300} height={300}></svg>;
};

export default PopupGraph;
