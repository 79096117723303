import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function D3Graph({ nodesData, onNodeClick, selectedNodes }) {
    const ref = useRef(null);

    useEffect(() => {
        const svg = d3.select(ref.current);
        const width = window.innerWidth;
        const height = window.innerHeight;

        svg.selectAll("*").remove(); // Clear previous SVG contents

        const zoom = d3.zoom()
            .scaleExtent([0.5, 4])
            .on("zoom", (event) => {
                g.attr("transform", event.transform);
            });

        svg.call(zoom).attr("viewBox", `0 0 ${width} ${height}`);

        const g = svg.append('g');

        const getNodeRadius = text => Math.max(20, text.length * 4+3);

        const simulation = d3.forceSimulation(nodesData)
            .force("charge", d3.forceManyBody().strength(-30))
            .force("center", d3.forceCenter(width / 2, height / 2))
            .force("collision", d3.forceCollide(d => getNodeRadius(d.name) + 2));

        const nodes = g.selectAll("circle")
            .data(nodesData)
            .enter()
            .append("circle")
            .attr("r", d => getNodeRadius(d.name))
            .attr("fill", "white")
            .attr("stroke", d => selectedNodes.has(d.name) ? "green" : "blue")
            .attr("stroke-width", 3)
            .on("click", (event, d) => onNodeClick(d));

        const labels = g.selectAll(".label")
            .data(nodesData)
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "central")
            .text(d => d.name)
            .on("click", (event, d) => onNodeClick(d));

        simulation.on("tick", () => {
            nodes.attr("cx", d => d.x)
                 .attr("cy", d => d.y);
            labels.attr("x", d => d.x)
                  .attr("y", d => d.y);
        });

        return () => {
            simulation.stop(); // Cleanup simulation when the component unmounts
        };
    }, [nodesData, onNodeClick, selectedNodes]); // React to changes in selectedNodes

    return <svg ref={ref} style={{ width: '100%', height: '100%' }}></svg>;
}

export default D3Graph;