import React from 'react';
import { Modal, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import PopupGraph from './PopupGraph'; // Import your PopupGraph component

const WordPopup = ({ word, details, relatedWords, onSelectWord, onClose, selectedNodes }) => {
    const navigate = useNavigate();

    // This function handles the selection logic and navigation
    const finishSelection = () => {
        onSelectWord(); // Call the onSelectWord function if needed to manage selection state
        navigate('/second-page', { state: { word, details, relatedWords, selectedWords: Array.from(selectedNodes) } });
    };

    const nodes = [{ id: word, color: "blue" }, ...relatedWords.map(rw => ({ id: rw, color: "green" }))];
    const links = relatedWords.map(rw => ({ source: word, target: rw, value: 1 }));

    return (
        <Modal
            title={`${word}`}
            visible={true}
            onCancel={onClose}
            footer={[
                <Button key="select" onClick={onSelectWord}>Select Word</Button>,
                <Button key="finish" type="primary" onClick={finishSelection}>Finish Selection</Button>
            ]}
        >
            <p><strong>Sentence:</strong> {details && details.sentence ? details.sentence : 'Loading sentence...'}</p>
            <p><strong>Translation:</strong> {details && details.translation ? details.translation : 'Loading translation...'}</p>
            <PopupGraph nodes={nodes} links={links} />
            <img src={`/images/${word}.png`} alt={word} style={{ width: '100%', height: '100%' }} />
        </Modal>
    );
};

export default WordPopup;
