import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import WordPopup from './components/WordPopup';
import D3Graph from './components/D3Graph';
import './styles/App.css';

const { Content } = Layout;

function App() {
    const [nodesData, setNodesData] = useState([{ name: 'abandon', x: 300, y: 400 }]);
    const [currentWord, setCurrentWord] = useState(null);
    const [selectedNodes, setSelectedNodes] = useState(new Set()); // Track all selected nodes
    const [showPopup, setShowPopup] = useState(false);
    const [wordDetails, setWordDetails] = useState({});
    const [edges, setEdges] = useState([]);
    const [relatedWords, setRelatedWords] = useState([]);

    useEffect(() => {
        fetch('/data/high_school_word_edges.json')
            .then(response => response.json())
            .then(data => setEdges(data))
            .catch(error => console.error('Failed to load word edges:', error));

        fetch('/data/word_details.json')
            .then(response => response.json())
            .then(data => setWordDetails(data))
            .catch(error => console.error('Failed to load word details:', error));
    }, []);

    const handleNodeClick = (node) => {
        setCurrentWord(node.name);
        const relatedEdges = edges.filter(edge =>
            edge.word1 === node.name || edge.word2 === node.name
        );
        const newRelatedWords = relatedEdges.map(edge => 
            edge.word1 === node.name ? edge.word2 : edge.word1
        );
        setRelatedWords(newRelatedWords);
        setShowPopup(true);
    };

    const onSelectWord = () => {
        selectedNodes.add(currentWord); // Add to selected nodes
        setSelectedNodes(new Set(selectedNodes)); // Trigger re-render

        const relatedEdges = edges.filter(edge =>
            edge.word1 === currentWord || edge.word2 === currentWord
        );

        const newNodes = relatedEdges.map(edge => ({
            name: edge.word1 === currentWord ? edge.word2 : edge.word1,
            x: window.innerWidth / 2 + (Math.random() - 0.5) * 200,
            y: window.innerHeight / 2 + (Math.random() - 0.5) * 200
        }));

        // Filter out existing nodes
        const newWordsSet = new Set(newNodes.map(node => node.name));
        setNodesData(prevNodes => prevNodes.filter(node => !newWordsSet.has(node.name)).concat(newNodes));

        setShowPopup(false);
    };

    return (
        <Layout className="layout">
            <Content style={{ height: '100vh' }}>
                <D3Graph nodesData={nodesData} onNodeClick={handleNodeClick} selectedNodes={selectedNodes} />
                {showPopup && currentWord && (
                    <WordPopup
                        word={currentWord}
                        details={wordDetails[currentWord] || {}}
                        relatedWords={relatedWords}
                        onSelectWord={onSelectWord}
                        onClose={() => setShowPopup(false)}
                        selectedNodes={selectedNodes}
                    />
                )}
            </Content>
        </Layout>
    );
}

export default App;
