import React from 'react';
import { Layout, Card, Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const SecondPage = () => {
    const location = useLocation();
    const { selectedWords } = location.state || { selectedWords: [] };
    console.log(selectedWords);
    return (
        <Layout className="layout">
            <Content style={{ padding: '20px' }}>
                <Row gutter={[16, 16]}>
                    {selectedWords.map(word => (
                        <Col key={word} xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Card title={word} bordered={false}>
                                <img src={`/images/${word}.png`} alt={word} style={{ width: '100%', height: '150px', objectFit: 'contain' }} />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Content>
        </Layout>
    );
};

export default SecondPage;
